import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

// const prodUrl = "http://3.134.155.187:8000/"
const prodUrl = "https://prod.rentnet.in/"

axios.interceptors.request.use((config) => {
        const token = localStorage.getItem("app_access_token");
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }


        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use((response) => {
        return response;
    },

    function(error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry && localStorage.getItem('app_refresh_token')) {
            console.log(localStorage.getItem('app_refresh_token'))
            error.config._retry = true;


            return axios.post(`https://prod.rentnet.in/api/token/refresh/`, {
                refresh: localStorage.getItem('app_refresh_token')
            })

            .then(res => {
                console.log(res)
                    //Setting up access token in Local Storage
                localStorage.setItem('app_access_token', res.data.access)
                    //Changing Authorization
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('app_access_token');
                return axios(originalRequest)
            })
        }
        return Promise.reject(error);
    }
);

export const loginUser = async(user) => {
    const url = prodUrl + "api/token/";
    try {
        const res = await axios.post(url, user);
        console.log("This is the res", res.status);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const registerUser = async(user) => {
    const url = prodUrl + "register/";
    try {
        const res = await axios.post(url, user);
        console.log("This is the res", res);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}


export const loginByFacebook = async() => {

    let provider = new firebase.auth.FacebookAuthProvider();
    let result = await firebase.auth().signInWithPopup(provider)

    return result;

}

export const loginByGoogle = async() => {
    let provider = new firebase.auth.GoogleAuthProvider();
    let result = await firebase.auth().signInWithPopup(provider);

    return result;

}

// Building page

export const buildingPage1 = async(apiDetails) => {
    const apiUrl = `${prodUrl}owners/building1/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const buildingPage2 = async(id, files) => {
    const apiUrl = `${prodUrl}owners/building2/`;
    try {
        var bodyFormData = new FormData();
        bodyFormData.append('building_id', id);
        files.map((item, index) => {
            bodyFormData.append(`file_${index+1}`, item.image);
            bodyFormData.append(`caption_${index+1}`, item.caption)
        })

        const res = await axios({ method: "post", url: apiUrl, data: bodyFormData, headers: { "Content-Type": "multipart/form-data" } });
        console.log(res);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const buildingPage3 = async(apiDetails) => {
    const apiUrl = `${prodUrl}owners/building3/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

// Tenant Pages

export const tenantPage1 = async(apiDetails) => {
    const apiUrl = `${prodUrl}owners/tenant1/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const tenantPage2 = async(apiDetails) => {
    const apiUrl = `${prodUrl}owners/tenant2/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const tenantPage3 = async(apiDetails) => {
    const apiUrl = `${prodUrl}owners/tenant3/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

// Get Apis for BUilding page
export const getBuildingInfo = async() => {
    const apiUrl = `${prodUrl}owners/fetch-building-details/1/`;
    try {
        const res = await axios.get(apiUrl);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const getallbuilding = async() => {
    const apiUrl = `${prodUrl}owners/fetch-all-buildings/`;
    try {
        const res = await axios.get(apiUrl);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const getAmenities = async() => {
    const apiUrl = `${prodUrl}owners/amn_view/`;
    try {
        const res = await axios.get(apiUrl);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const getAllTenants = async(body) => {
    let apiUrl = `${prodUrl}owners/fetch-all-tenants/`;
    // const apiUrl = `${prodUrl}owners/fetch-all-tenants/`;
    try {
        const res = await axios.get(apiUrl,{ params: { ...body } });
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }

}
export const getFinance = async(body) => {
    const apiUrl = `${prodUrl}financial/financial-table/`;
    try {
        const res = await axios.get(apiUrl,{ params: { ...body } });
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const getUnits = async(body) => {
    let apiUrl = `${prodUrl}owners/fetch-all-units/`;
    try {
        const res = await axios.get(apiUrl,{ params: { ...body } });
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const expTenant = async() => {
    const apiUrl = `https://prod.rentnet.in/owners/tenants-export/`;
    try {
        const res = await axios.get(apiUrl);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const expFinance = async() => {
    const apiUrl = `${prodUrl}financial/export/`;
    try {
        const res = await axios.get(apiUrl);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const impTenants = async(apiDetails) => {
    const apiUrl = `${prodUrl}owners/import-tenants/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const createExp = async(apiDetails) => {

    var bodyFormData = new FormData();

    bodyFormData.append('date', apiDetails.date);
    bodyFormData.append('building_id', apiDetails.building);
    bodyFormData.append('finance_type', "Expense");
    bodyFormData.append('finance_name', apiDetails.finance_name);
    bodyFormData.append('payment_method', apiDetails.payment_method);
    bodyFormData.append('expense_frequency', apiDetails.expense_frequency);
    bodyFormData.append('expense_category', apiDetails.expense_category);
    bodyFormData.append('image', apiDetails.image);

    const apiUrl = `${prodUrl}financial/create-expense/`;
    try {
        const res = await axios({ method: "post", url: apiUrl, data: bodyFormData, headers: { "Content-Type": "multipart/form-data" } });
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}
export const createCollection = async(apiDetails) => {
    const apiUrl = `${prodUrl}financial/create-collection/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const createUnit = async(apiDetails) => {
    const apiUrl = `${prodUrl}owners/create-unit/`;
    try {
        const res = await axios.post(apiUrl, apiDetails);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const finAnalytics = async() => {
    const apiUrl = `https://prod.rentnet.in/dashboard/financial-analytics/`;
    try {
        const res = await axios.get(apiUrl);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const tenAnalytics = async() => {
    const apiUrl = `https://prod.rentnet.in/dashboard/tenants-analytics/`;
    try {
        const res = await axios.get(apiUrl);
        return res.data;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const getUnitsByBuilding = async(id) => {
    const apiUrl = `${prodUrl}owners/fetch-units-by-building/${id}/`;
    const res = await axios.get(apiUrl);
    return res;
}

export const getCategories = async() => {
    const apiUrl = `${prodUrl}financial/expense-category/`;
    const res = axios.get(apiUrl);
    return res;
}
export const getCurrentTenant = async(id) => {
    const apiUrl = `${prodUrl}owners/fetch-tenant-details/${id}`;
    const res = axios.get(apiUrl);
    return res;
}
export const getCurrentBuilding = async(id) => {
    const apiUrl = `${prodUrl}owners/fetch-building-details/${id}`;
    const res = axios.get(apiUrl);
    return res;
}

export const getDashboardFinancial = async() => {
    const apiUrl = `${prodUrl}dashboard/financial-analytics/`;
    const res = axios.get(apiUrl);
    return res;
}

export const getDashboardTenant = async() => {
    const apiUrl = `${prodUrl}dashboard/tenants-analytics/`;
    const res = axios.get(apiUrl);
    return res;
}

export const updateUnit = async(id, body) => {
    const apiUrl = `${prodUrl}owners/update-unit-details/${id}/`;
    const res = axios.put(apiUrl, body);
    return res;
}

// owners/update-unit-details/